import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import Honeymoon1 from '../images/beachmoon4.png';
import Honeymoon2 from '../images/beachmoon3.png';
import Honeymoon3 from '../images/beachmoon2.png';
import Honeymoon4 from '../images/beachmoon.png';
import Navbarback from '../components/Navbarback';

const PackageRomantic = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Romantic Honymoon Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={Honeymoon4} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={Honeymoon3} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={Honeymoon2} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={Honeymoon1} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={Honeymoon4} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>Sri Lanka is a tear drop shaped island in the Indian Ocean and is blessed with all the ingredients for a perfect sun and sand holiday. The endless golden sand beaches trimmed with palm trees… beautiful Hill Sides in Sri Lanka is surrounded with Castling waterfalls and tea states most romantic hide outs. The memorable honeymoon place are covered in short time with Luxury and more Romantic in this Sri lanka honeymoon package… </p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price Per Person.</li>
            <li>✔ Special Ayurveda foot massage.</li>
            <li>✔ Candle light Dinner on Request.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Early Check in & Late Check Out</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Kalutara - Kandy - Negombo
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Honymoon Package</h4>
              <h3 className="text-center">490$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Explore the Golden Beaches of Sri Lanka                </li>
                <li>✔ Explore the Hill Capital of Sri Lanka</li>
                <li>✔ Enjoy the Scenic beauty of the country with teal plantation and waterfalls</li>
                <li>✔ Explore Colombo Ctiy tour with Last minute Shopping</li>
                <li>✔ Explore Negombo the beautiful West coast of Sri Lanka</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageRomantic;