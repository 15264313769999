import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import city1 from '../images/colombo-colombo-city.jpg';
import city2 from '../images/mall.png';
import city4 from '../images/red mos2q.png';
import city3 from '../images/lotus.png';
import city5 from '../images/building.png';
import Navbarback from '../components/Navbarback';


const PackageCity = () => (
  <div>
    <Navbarback/>

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Colombo City Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={city5} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={city4} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={city3} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={city1} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={city2} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>The Commercial capital of Sri Lanka “Colombo” is a stunning luxury City break and the also perfect key location to meet the commercial Arrangement and night life. Hide out at Luxury Colombo OR Negombo hotels with your Lovable family or Romantic couple and arrange your excursion the Satisfy your commercial or other needful.</p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Early Check in & Late Check out</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Colombo
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">City Tour Highlights</h4>
              <h3 className="text-center">330$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Explore the Commercial Capital of Sri Lanka</li>
                <li>✔ Enjoy the Nightlife in Colombo</li>
                <li>✔ Enjoy the Shopping in Colombo</li>
                <li>✔ Visits the Dambulla Curve Temple</li>
                <li>✔ Explore Negombo the beautiful West coast of Sri Lanka</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageCity;