import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap JS is included
import './Navbar.css'; // Import custom CSS for the navbar

const Navbar = () => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-lg">
    <div className="container">
      <a className="navbar-brand" href="/">Travelbae Holiday</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <i class="bi bi-three-dots"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link" href="/">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#blog">Blogs</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Offers">Offers</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Tour Packages">Packages</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Navbar;
