import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import round1 from '../images/full round.jpg';
import round2 from '../images/botanical.png';
import round3 from '../images/sigirya1.png';
import round4 from '../images/pinnawala.png';
import round5 from '../images/traintour.png';
import Navbarback from '../components/Navbarback';


const PackageRound = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Round Tour Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={round5} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={round1} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={round3} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={round2} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={round4} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>Sri Lanka is a tropical Island covers with Sandy beaches and Misty hills, as well its merely consist about rich in heritage and thrilling adventures as. Explore this our sri lanka round tour packages with Cultural Triangle, Scenic hill side, Thrilling adventures, Scarcely Wild life and Sandy Beaches. The best time to Explore it on October to May</p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price For Per Person with Minimum of Two pax.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Early Check in & Late Check Out </li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Negombo - Anuradhapura - Polanaruwa - Sigiriya - Pinnewela - Kandy - Nuwara Eliya - Ella - Yala - Mirissa - Galle - Colombo
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Full Pack </h4>
              <h3 className="text-center">900$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Explore The Cultural Triangle  </li>
                <li>✔ Explore The UNESCO Sites of the Country                </li>
                <li>✔ Rural Activities                </li>
                <li>✔ Explore the hill tour with tea & train trill                </li>
                <li>✔ Explore the royal Kingdom of Sri Lanka "Kandy"</li>
                <li>✔ Wild Safari Excursions                </li>
                <li>✔ Dolphin & Wahle watching at mirissa                </li>
                <li>✔ Enjoy the breezy wind with Colorful Sunset at South Coast Beach</li>
                <li>✔ Leisure at South Coast & Water Sports</li>
                <li>✔ Explore the Commercial capita of Sri Lanka</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageRound;