import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import lux1 from '../images/luxuryhotel4.png';
import lux2 from '../images/luxuryhotel3.png';
import lux3 from '../images/luxuryhotel1.png';
import lux4 from '../images/luxury.jpg';
import lux5 from '../images/luxuryhotel2.png';
import Navbarback from '../components/Navbarback';


const PackageLuxury = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Luxury Stay Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={lux2} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={lux1} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={lux5} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={lux3} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={lux4} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>Explore the Sri Lanka luxury holidays with Staying in Sri Lanka Stylish Luxurious holiday Boutique hotels and resorts. Find the best price and deals to Exploring to the sightseen of Sri Lanka Scenic trails Heritage Rest and relaxation at Beach and Roaming in the Capital Cities. This Sri Lanka tour Itinerary is more healthy for Families and Couple</p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price For Per Person with Minimum of Two pax.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Colombo - Sigiriya - Kandy - Nuwaraliya - Negombo
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Luxury Highlights</h4>
              <h3 className="text-center">690$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Explore The Capital City Of Sri Lanka</li>
                <li>✔ Visits Elephant Millennium and Orphanage</li>
                <li>✔ Visits the beautiful Heritage sites and eight wonders of Asia Sigiriya Lion Rock</li>
                <li>✔ Visits the Dambulla Curve Temple</li>
                <li>✔ Explore the royal Kingdom of Sri Lanka "Kandy"</li>
                <li>✔ Enjoy the beauties of hillside by the train</li>
                <li>✔ Enjoy and Relax at Sea touches the Sunny beaches</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageLuxury;