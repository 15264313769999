import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import EllaImg from '../images/Ella.png';
import SigiriyaImg from '../images/Sigiriya.png';
import BeachImg from '../images/Beach.png';
import AdvantureImg from '../images/Safari Srilankja.jpeg';
import BeachpackImg from '../images/beach side.jpg';
import cmbCity from '../images/colombo-colombo-city.jpg';
import RomanticPack from '../images/Honeymoon-in-Sri-Lanka-3-1.jpg';
import Luxurypack from '../images/luxury.jpg';
import Fampack from '../images/family packa.png';
import RoundPack from '../images/full round.jpg';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import NuwaraPack from '../images/package-nuwara.jpeg';
import BussImg from '../images/buss.jpg';
import Carimg from '../images/carina.png';
import KDHimg from '../images/KDH .png';
import audi from '../images/audi.png';
import offer from '../images/Offer.png';
import blogSigiriya from '../images/sigiriya-blog1.jpeg';
import blogLake from '../images/lake-blog.jpg';
import blogjafna from '../images/jafnafort-blog.jpg';
import blogPinwala from '../images/pinnwla-blog.jpg';
import blogninearch from '../images/ninearch-blog.jpg';
import blogmirissa from '../images/mirissa-blog.jpg';
import trainbg from '../images/ninearch-blog.jpg';
import { Helmet } from 'react-helmet';

const slides = [
  {
    image: trainbg,
    content: 'Enjoy your Sri Lanka journey with us',
  },
  {
    image: SigiriyaImg,
    content: 'Discover the beauty of Sri Lanka with our expert guides',
  },
  {
    image: BeachImg,
    content: 'Experience the best of Sri Lanka’s culture and nature',
  },
];

const Home = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      
      <helmet>
      <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="keywords" content="Travel Bae Holiday,Travelbae Holiday, Travelbaeholiday, Travel bae holiday srilanka, srilanka tour packages, tour guide srilanka, tour guide negombo, " />
          <title>Home - Travelbae Holiday</title>
    <meta
      name="description"
      content="Explore unforgettable travel experiences with Travelbae Holiday. Discover top destinations, tailor-made itineraries, and expert travel guides to make your dream vacation a reality. Book your next adventure with ease and confidence."
    />
      </helmet>

      <Navbar />
      <section className="hero relative h-screen text-white flex items-center justify-center overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${slides[currentSlideIndex].image})` }}
        ></div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <h1 className="relative z-10 text-4xl md:text-6xl font-bold">
          {slides[currentSlideIndex].content}
        </h1>
      </section>
      
      <section className="why-choose-us py-16 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Why Choose Us</h2>
          <div className="content-why flex justify-around">
            <div className="text-center">
              <i className="icon bi bi-clock-history"></i>
              <p>24/7 Customer Service</p>
            </div>
            <div className="text-center">
              <i className="icon bi bi-hand-thumbs-up"></i>
              <p>Good Feedback</p>
            </div>
            <div className="text-center">
              <i className="icon bi bi-cash-coin"></i>
              <p>Standard Service With Less Charges</p>
            </div>
            <div className="text-center">
              <i className="icon bi bi-luggage"></i>
              <p>Best Travel Experts</p>
            </div>
          </div>
        </div>
      </section>   <br /><br />   <br /><br />

 
      <section className="py-16" id="Tour Packages">
        <h2 className="text-3xl font-bold text-center">Tour Packages</h2> <br/>
        
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={NuwaraPack} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Upcountry Tour</h5>
                  <p className="card-text">Stay in the Tea & Hills.</p>
                  <a href="/package-upcountry" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={AdvantureImg} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Advanture & Wild Life</h5>
                  <p className="card-text">The Best Outdoor Activities.</p>
                  <a href="/package-wild" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={BeachpackImg} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Beach Side</h5>
                  <p className="card-text">Explore The Beach Vacations.</p>
                  <a href="package-beach" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={cmbCity} alt="Upcountry" height={'100rem'}/>
                <div className="card-body">
                  <h5 className="card-title">Colombo City</h5>
                  <p className="card-text">Capital Of The Srilanka.</p>
                  <a href="/package-city" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
           </div>
          <div className="row mt-4">
          <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={RomanticPack} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Romantic Honeymoon</h5>
                  <p className="card-text">The memorable honeymoon.</p>
                  <a href="/package-romantic" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={Luxurypack} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Luxury Hotels & Resorts</h5>
                  <p className="card-text">Roaming in the Capital Cities.</p>
                  <a href="/package-luxury" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
               <img className="card-img-top" src={Fampack} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">For Family</h5>
                  <p className="card-text">Family holidays packages.</p>
                  <a href="/package-fam" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="card-img-top" src={RoundPack} alt="Upcountry" />
                <div className="card-body">
                  <h5 className="card-title">Round Trip</h5>
                  <p className="card-text">Tropical Island Cover.</p>
                  <a href="/package-round" className="btn btn-primary">view Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> <br /><br /><br /><br />

      
      <section>
    <div className="container">
      <h2 className="text-3xl font-bold text-center">Bulk Bookings</h2>
      <br />
      <div className="card mb-1">
        <div className="row">
          <div className="col-md-4">
            {/* Image Slider */}
            <div id="vehicleSlider" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={Carimg} className="d-block w-100 img-fluid rounded-start fixed-height" alt="Bus" />
                </div>
                <div className="carousel-item">
                  <img src={audi} className="d-block w-100 img-fluid rounded-start fixed-height" alt="Car" />
                </div>
                <div className="carousel-item">
                  <img src={KDHimg} className="d-block w-100 img-fluid rounded-start fixed-height" alt="Van" />
                </div>
                <div className="carousel-item">
                  <img src={BussImg} className="d-block w-100 img-fluid rounded-start fixed-height" alt="Van" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#vehicleSlider" data-bs-slide="prev">
              <i class="bi bi-arrow-left"></i>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#vehicleSlider" data-bs-slide="next">
              <i class="bi bi-arrow-right"></i>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Book A Vehicle</h5>
              <p className="card-text">
                If you're a large team in search of the best travel experience, we have the perfect solution for you. Whether you're
                looking to book a bus, car, or van, our luxurious and spacious vehicles are designed to accommodate your group's needs.
                Offering comfort and style on every journey, our vehicles come with modern amenities and plush seating to ensure
                everyone travels in complete relaxation. Whether you're planning a corporate event, group tour, or any other outing,
                we can provide a top-tier travel experience tailored to your needs. Let us handle the transportation logistics so
                you can focus on enjoying the trip. Choose our service for a seamless, comfortable, and unforgettable travel experience.
              </p>
              <a href="https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Vehicle%20With%20You." className="btn btn-dark">Book Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><br /><br />


      <section className='h-25 container  d-flex justify-content-center align-items-center' id='Offers'>
  <img 
    src={offer} 
    className="img-fluid" 
    alt="Responsive image" 
  />
</section> <br /><br /> <br />

      
      <section>
      <h2 className="text-3xl font-bold text-center" id='blog'>Popular Destination</h2> <br/>

      <div className="container">
    <div className="row">
      {/* Card 1 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogSigiriya})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Sigiriya</h2>
            <p className="card-text">
            Sigiriya, Sri Lanka's iconic ancient rock fortress with stunning frescoes and breathtaking views. A UNESCO World Heritage site, it offers a glimpse into the island's rich history and architecture.
            </p>
          </div>
        </div>
      </div>

      {/* Card 2 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogjafna})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Jaffna Fort</h2>
            <p className="card-text">
              Explore Jaffna Fort, a historical gem in Sri Lanka's northern region. This 17th-century fort showcases Portuguese and Dutch colonial architecture and offers a peek into the area's storied past.
            </p>
          </div>
        </div>
      </div>

      {/* Card 3 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogLake})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Lake Gregory</h2>
            <p className="card-text">
            Enjoy boat rides on Lake Gregory in Nuwara Eliya. Surrounded by lush greenery and cool climate, this picturesque lake is perfect for relaxation and leisurely strolls.
            </p>
          </div>
        </div>
      </div>

      {/* Card 4 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogPinwala})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Pinnawala Elephant Orphanage</h2>
            <p className="card-text">
          Pinnawala Elephant Orphanage, witness the care of orphaned elephants. Feed and bathe these majestic creatures while learning about conservation efforts in this heartwarming sanctuary.
            </p>
          </div>
        </div>
      </div>

      {/* Card 5 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogninearch})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Nine arch bridge</h2>
            <p className="card-text">
           The Nine Arch Bridge in Ella, an engineering marvel set amidst lush tea plantations. This stunning viaduct is a popular spot for photography and train enthusiasts alike.
            </p>
          </div>
        </div>
      </div>

      {/* Card 6 */}
      <div className="col-md-4 mb-4">
        <div
          className="card text-white position-relative"
          style={{
            width: '100%',
            backgroundImage: `url(${blogmirissa})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px',
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          ></div>
          <div className="card-body position-relative" style={{ zIndex: 2 }}>
            <h2 className="card-title text-center fw-bolder">Mirissa</h2>
            <p className="card-text ">
            Relax in Mirissa, a coastal haven known for its golden beaches and vibrant marine life. Ideal for whale watching, sunbathing, and enjoying a laid-back beach vibe.            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
      </section>

      
      <WhatsAppButton />
      <Footer />
    </div>
  );
};

export default Home;
