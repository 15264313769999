import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import wild1 from '../images/wiled2.jpg';
import wild2 from '../images/wild1.png';
import wild3 from '../images/village.jpg';
import wild4 from '../images/advanture1.png';
import wild5 from '../images/Safari Srilankja.jpeg';
import Navbarback from '../components/Navbarback';



const PackageWild = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Advanture & Wild Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={wild4} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={wild1} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={wild3} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={wild2} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={wild5} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>A dazzling list of adventures that takes you through the whole island; witness the endless possibilities in Sri Lanka for the finest adventure tour experience. The Perl Island is blessed with plenty of adventure yet to be explored; Water sports to flora & fauna, cycling to hiking and cliff climbing to waterfalls; all the list of adventures in your books waits just for you. A comprehensive adventure tour coverage in Sri Lanka with the must-do adventures on the list.</p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Only Breakfast at Coastal Side Hotel (Weligama).</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Lunch</li>
            <li>✘ Dinner at Coastal Side Hotel (Weligama)</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Airport - Sigiriya - Kandy - Nuwara  -  Yala - Sinharaja - Weligama - Airport
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Holiday Highlights</h4>
              <h3 className="text-center">890$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Exploring a Culturala Sites of Sri Lanka</li>
                <li>✔ Visits Elephant Millennium and Orphanage</li>
                <li>✔ Cycling in the Rural Area</li>
                <li>✔ Luxury Train Travel In hill Country</li>
                <li>✔ Explore the royal Kingdom of Sri Lanka "Kandy"</li>
                <li>✔ White water Rafting</li>
                <li>✔ Climbing Adam speak </li>
                <li>✔ Enjoing the Yala Safari Campsite </li>
                <li>✔ Exploring SInharaja Rain Forest </li>
                <li>✔ Doing Thrilling Water Adventure Activities </li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageWild;