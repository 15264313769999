import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';

const HolidayPackages = () => (
  <div>
    <Navbar />
    <section className="holiday-packages bg-cover h-64 text-white flex items-center justify-center">
      <h1 className="text-3xl md:text-5xl font-bold">Explore Our Tour Packages</h1>
    </section>
    <section className="packages py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Our Packages</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Repeat this block for each package */}
          <div className="card bg-white p-4 rounded-lg shadow-lg">
            <img src="package.jpg" alt="Package" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Package Name</h3>
            <p>Details about the package.</p>
            <button className="bg-green-500 text-white py-2 px-4 mt-4 rounded">View Details</button>
          </div>
          {/* Add more package cards here */}
        </div>
      </div>
    </section>
    <Footer />
    <WhatsAppButton />
  </div>
);

export default HolidayPackages;
