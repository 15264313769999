import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import upc1 from '../images/package-nuwara.jpeg';
import upc2 from '../images/kandy.jpg';
import upc3 from '../images/hotels newa1.png';
import upc4 from '../images/hotels newa.png';
import upc5 from '../images/train.png';
import Navbarback from '../components/Navbarback';


const PackageUpcountry = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Upcountry Hill & Tea Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={upc5} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={upc2} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={upc1} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={upc3} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={upc4} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>Explore the Sri Lanka luxury holidays with Staying in Sri Lanka Stylish Luxurious holiday Boutique hotels and resorts. Find the best price and deals to Exploring to the sightseen of Sri Lanka Scenic trails Heritage Rest and relaxation at Beach and Roaming in the Capital Cities. This Sri Lanka tour Itinerary is more healthy for Families and Couple</p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price For Per Person with Minimum of Two pax.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p>Pinnewela - Kandy - Nuwara Eliya - Ella - Yala 
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Upcountry Highlights</h4>
              <h3 className="text-center">690$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Explore The Capital City Of Sri Lanka</li>
                <li>✔ Visits Elephant Millennium and Orphanage</li>
                <li>✔ Visits the beautiful Heritage sites and eight wonders of Asia Sigiriya Lion Rock</li>
                <li>✔ Visits the Dambulla Curve Temple</li>
                <li>✔ Explore the royal Kingdom of Sri Lanka "Kandy"</li>
                <li>✔ Enjoy the beauties of hillside by the train</li>
                <li>✔ Enjoy and Relax at Sea touches the Sunny beaches</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageUpcountry;