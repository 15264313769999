import React from 'react';

const Footer = () => (
  <div>
    <footer className="py-3 mt-4" style={{ backgroundColor: 'black', color: 'white' }}>
      <h2 className="card-title text-center fw-bolder">About Us</h2> <br />
      <p className="card-text justify-content-center text-center">
        With 25 years of experience, our expert tour guide offers an unparalleled exploration of Sri Lanka.
        From the vibrant cities to hidden gems across the island, we provide in-depth knowledge and a personalized
        experience. Available 24 hours a day, we ensure your journey is seamless, enriching,
        and filled with unforgettable moments. Discover Sri Lanka with a guide who knows every corner
        of this beautiful island.
      </p>
      <ul className="nav justify-content-center pb-0 mb-3 gap-4" style={navStyle}>
        <li className="nav-item text-center">
          <a className="text-white" href="https://www.facebook.com/share/XM3vinuPuCWfiEqK/?mibextid=LQQJ4d">
            facebook
            <div>
              <i className="bi bi-facebook mt-2"></i>
            </div>
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="text-white" href="https://www.instagram.com/travel_bae_holidays?igsh=dGVrb3N3d3F3NGI0">
            instagram
            <div>
              <i className="bi bi-instagram mt-2"></i>
            </div>
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="text-white" href="mailto:travelbaeholiday@gmail.com">
            E mail
            <div>
              <i className="bi bi-envelope mt-2"></i>
            </div>
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="text-white" href="https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You">
            whatsapp
            <div>
              <i className="bi bi-whatsapp mt-2"></i>
            </div>
          </a>
        </li>
      </ul>
    </footer>
  </div>
);

const navStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: 5,
  flexWrap: 'wrap',
};

const styles = `
  @media (min-width: 768px) {
    .nav {
      flex-wrap: nowrap !important;
    }
    .nav-item {
      margin: 0 15px;
    }
  }

  @media (max-width: 767px) {
    .nav-item {
      margin: 5px 0;
      flex: 1 1 50%;
    }
  }
`;

export default Footer;
