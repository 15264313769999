import React from 'react';

const WhatsAppButton = () => (
  <a
    href="https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You."
    className="fixed bottom-4 right-1 bg-green text-white rounded-full shadow-lg"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      backgroundColor: '#25D366',
      color: 'white',
      padding: '10px',
      borderRadius: '20%',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      fontSize: '20px', // Adjust icon size
    }}
  >
    <i className="bi bi-whatsapp"></i>
  </a>
);

export default WhatsAppButton;
