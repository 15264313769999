import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';

const HolidayHotels = () => (
  <div>
    <Navbar />
    <section className="holiday-hotels bg-cover h-64 text-white flex items-center justify-center">
      <h1 className="text-3xl md:text-5xl font-bold">Best Hotels in Sri Lanka</h1>
    </section>
    <section className="hotels py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Our Hotels</h2>
        <p className="text-center mb-8">We offer 3-star, 5-star, and 7-star hotels at the best prices.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Repeat this block for each hotel */}
          <div className="card bg-white p-4 rounded-lg shadow-lg">
            <img src="hotel.jpg" alt="Hotel" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">3-Star Hotel</h3>
            <p>Details and price for 3-star hotels.</p>
            <button className="bg-green-500 text-white py-2 px-4 mt-4 rounded">Book</button>
          </div>
          {/* Add more hotel cards here */}
        </div>
      </div>
    </section>
    <Footer />
    <WhatsAppButton />
  </div>
);

export default HolidayHotels;
