
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import HolidayTransfers from './pages/HolidayTransfers';
import HolidayHotels from './pages/HolidayHotels';
import HolidayPackages from './pages/HolidayPackages';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PackageUpcountry from './pages/PackageUpcountry';
import PackageWild from './pages/PackageWild';
import PackageRomantic from './pages/PackageRomantic';
import PackageLuxury from './pages/PackageLuxury';
import PackageRound from './pages/PackageRound';
import PackageBeach from './pages/PackageBeach';
import PackageCity from './pages/PackageCity';
import PackageFamily from './pages/PackageFamily';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/holiday-transfers" element={<HolidayTransfers />} />
        <Route path="/holiday-hotels" element={<HolidayHotels />} />
        <Route path="/holiday-packages" element={<HolidayPackages />} />
        <Route path="/package-upcountry" element={<PackageUpcountry />} />
        <Route path='/package-wild' element={<PackageWild />} />
        <Route path='/package-Romantic' element={<PackageRomantic />} />
        <Route path='/package-luxury' element={<PackageLuxury />} />
        <Route path='/package-round' element={<PackageRound />} />
        <Route path='/package-beach' element={<PackageBeach />} />
        <Route path='/package-city' element={<PackageCity />} />
        <Route path='/package-fam' element={<PackageFamily />} />

      </Routes>
    </Router>
  );
}

export default App;
