import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';

const HolidayTransfers = () => (
  <div>
    <Navbar />
    <section className="holiday-transfers bg-cover h-64 text-white flex items-center justify-center">
      <h1 className="text-3xl md:text-5xl font-bold">Best Drop and Pickup Service</h1>
    </section>
    <section className="services py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <p className="text-center mb-8">We offer car hire, van hire, and bus hire services.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="card bg-white p-4 rounded-lg shadow-lg">
            <img src="car.jpg" alt="Car" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Car Hire</h3>
            <p>Details and price for car hire.</p>
            <button className="bg-green-500 text-white py-2 px-4 mt-4 rounded">Book</button>
          </div>
          <div className="card bg-white p-4 rounded-lg shadow-lg">
            <img src="van.jpg" alt="Van" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Van Hire</h3>
            <p>Details and price for van hire.</p>
            <button className="bg-green-500 text-white py-2 px-4 mt-4 rounded">Book</button>
          </div>
          <div className="card bg-white p-4 rounded-lg shadow-lg">
            <img src="bus.jpg" alt="Bus" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Bus Hire</h3>
            <p>Details and price for bus hire.</p>
            <button className="bg-green-500 text-white py-2 px-4 mt-4 rounded">Book</button>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <WhatsAppButton />
  </div>
);

export default HolidayTransfers;
