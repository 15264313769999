import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import fam1 from '../images/family2.png';
import fam2 from '../images/family1.png';
import fam3 from '../images/family3.png';
import fam4 from '../images/family4.png';
import fam5 from '../images/family packa.png';
import Navbarback from '../components/Navbarback';


const PackageFamily = () => (
  <div>
    <Navbarback />

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Family Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={fam4} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={fam2} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={fam1} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={fam3} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={fam5} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>Sri Lanka is surrounded with Castling waterfalls and tea states most family hide outs. The beautiful scenic, Heritage, and coastal, Adventurous place are covered in short time with Luxury and  Family holidays packages. Our reliable Customer supportive team will customise and organise your Sri Lanka Family tours most charmed and memorable ways. </p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Price for Two Adult Two Children max 2 child under 5yrs.</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Double room With Extra Bed.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Family Pack</h4>
              <h3 className="text-center">900$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Visiting to the Small fishing town and west coast in Sri Lanka</li>
                <li>✔ Visits to Pinnawela Elephant millennium and Orphanage</li>
                <li>✔ Visits the beautiful Heritage sites and eight wonders of Asia Sigiriya Lion Rock</li>
                <li>✔ Visits Famous tooth relic temple in Kandy</li>
                <li>✔ Enjoy the Scenic beauty of the country with teal plantation and waterfalls</li>
                <li>✔ Enjoy the Water Sport and Thrilling adventure white water rafting</li>
                <li>✔ Enjoy the breezy wind with Colorful Sunset at South Coast Beach</li>
                <li>✔ Galle fort which is known as UNESCO heritage site in Sri Lanka</li>
                <li>✔ Last Minute Shopping at Colombo</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageFamily;