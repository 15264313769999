import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import NuwaraPack from '../images/package-nuwara.jpeg';
import './packagedetails.css';
import beach1 from '../images/beach.jpg';
import beach2 from '../images/beach side.jpg';
import beach5 from '../images/dive lanka.png';
import beach3 from '../images/beach2.jpg';
import beach4 from '../images/beach3.png';
import Navbarback from '../components/Navbarback';


const PackageBeach = () => (
  <div>
    <Navbarback/>

    <section className="details py-16 mt-5">
    <h1 className="text-3xl font-bold text-center">Beach Side Packages</h1> <br/>
      <div className="container" >
      <div className="images1 flex flex-col md:grid md:grid-cols-5 gap-4">
  <div className="large-image md:col-span-2">
    <img src={beach5} alt="Large Place" className="w-full h-full" width={'1200px'}/>
  </div>
  <div className="sm-images1 flex flex-wrap small-images grid grid-cols-2 gap-4 md:col-span-3">
    <img src={beach3} alt="Small Place 1" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={beach2} alt="Small Place 2" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={beach1} alt="Small Place 3" className="w-full h-full object-cover rounded-md" width={'200px'}/>
    <img src={beach4} alt="Small Place 4" className="w-full h-full object-cover rounded-md" width={'200px'}/>
  </div>
</div>

 <br />
        <div className="row">
        <div className="col-md-8">
          <h2>Overview</h2>
          <p>If you are a beach lover, then certainly you are in the right place to experience some of the finest beach experiences in the island within your time and budget. This tour plan focuses on beach and beach events including few other main attractions and important places that is a “MUST” in Sri Lanka. Our teams of experts have handpicked the hotels, beach and the romantic spots to give real value and make the holiday memorable.

            There’s a definite energy in the air, and tourism takes a back seat. However, this may not be the case for long. Some excellent boutique hotels have opened and locals are busily selling their often amazing unrestored vintage buildings to foreigners and speculators. </p>
          <h3>Includes</h3>
          <ul>
            <li>✔ Package Price .</li>
            <li>✔ Private Transfers with Fully Air condition & comfortable vehicle.</li>
            <li>✔ English Speaking and well experience Chauffeur for entire Excursion.</li>
            <li>✔ Accommodation at five Star Luxury Hotels and Resorts.</li>
            <li>✔ All the Hotel Meal with Breakfast.</li>
            <li>✔ Local Government taxes and Service chargers.</li>
          </ul>
          <h3>Excludes</h3>
          <ul>
            <li>✘ Visas & Flight Chargers</li>
            <li>✘ Entrance Fees for Excursions.</li>
            <li>✘ Dinner and Lunch</li>
            <li>✘ Private Expenses & Tips, port charges.</li>
          </ul>
          <h3 className="text-xl font-bold mt-4 mb-2">Travel Route</h3>
          <p> Negombo - Kandy - South Coast (Bentota)
          </p>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <h4 className="mb-2 text-muted text-center">Beach Side Highlights</h4>
              <h3 className="text-center">690$ <span className="text-muted">per person</span></h3>
              <ul>
                <li>✔ Visit to the Small fishing town and the popular West side beach Negombo</li>
                <li>✔ Visits to Pinnawela Elephant millennium and Orphanage</li>
                <li>✔ Visits sightseeing of most beautiful Scenic part in the Country (Kandy & NuwaraEliya)</li>
                <li>✔ Train tour in Hill Country</li>
                <li>✔ Eplore the Southern Coast beaches to Galle </li>
                <li>✔ Enjoy the Water Sports and Maduganaga River Safari</li>
              </ul>
            </div>
            <a href='https://api.whatsapp.com/send?phone=94760313493&text=Hey!%20I%20Need%20To%20Book%20A%20Trip%20With%20You.' 
            className="bg-primary text-white px-5 py-2 mb-2 rounded mx-auto link-underline-opacity-0"> Book Now</a>
          </div>
        </div>
      </div>
        </div>
    </section>
    
    <Footer />
    <WhatsAppButton />
  </div>
);

export default PackageBeach;